// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {

  apiKey: "AIzaSyD7bTv_cHdLHguWSJBKvxXKCgpgMlsOENA",

  authDomain: "ysbd-a5182.firebaseapp.com",

  databaseURL: "https://ysbd-a5182-default-rtdb.firebaseio.com",

  projectId: "ysbd-a5182",

  storageBucket: "ysbd-a5182.firebasestorage.app",

  messagingSenderId: "490196911553",

  appId: "1:490196911553:web:49b3d0ffb06af511d89b40",

  measurementId: "G-HB0NC89YRY"

};









// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };